<template>
    <div class="container ">
        <div class="col-wrap header">
            <div class="bg bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h3>ITRAX SERVICE</h3>
                    <h1>数字营销时代的品牌同行者</h1>
                    <p>Brand Explorer in the Age of Digital Marketing</p>
                </div>
            </div>
        </div>
        <div class="col-wrap service">
            <div class="bg"></div>
            <a id="service"></a>
            <div class="wrap-content">
                <h1>我们做什么</h1>
                <h2>What do we do?</h2>
                <p>爱创布局一个内容营销体系，两大核心业务模块以及四个创新沟通产品</p>
                <p>Itrax's Layout consists of one content-based marketing system, two core business modules and four innovative communication-based products</p>
                <ul>
                    <li><img src="@/assets/pc/service/service-2.jpg" /></li>
                    <li><img src="@/assets/pc/service/service-1.jpg" /></li>
                    <li><img src="@/assets/pc/service/service-3.jpg" /></li>
                </ul>
            </div>
        </div>
        <div class="col-wrap yingxiao">
            <div class="bg"></div>
            <div class="wrap-content">
                <div class="col-title">
                    <h1>内容营销体系</h1>
                    <div>Content-based Marketing System</div>
                </div>

                <ul>
                    <li @click="activeYx(0)" @mouseleave="closeYx(0)" :class="Yx[0] ? 'active' : ''">
                        <img src="@/assets/pc/service/yingxiao-1.jpg" />
                        <div class="mask">内容生产模块已构建四大实验室：算法数据实验室、圈层生态实验室、爆款标题实验室、创意制造实验室</div>
                    </li>
                    <li @click="activeYx(1)" @mouseleave="closeYx(1)" :class="Yx[1] ? 'active' : ''">
                        <img src="@/assets/pc/service/yingxiao-2.jpg" />
                        <div class="mask">内容分发平台通过信息的最大化扩散、深度渗透、跨界联动、口碑生态，抓住受众触点的全维度，进入全面“讨论区”</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-wrap yewu">
            <div class="wrap-content">
                <div class="col-title">
                    <h1>核心业务模块</h1>
                    <div>Core Business Module</div>
                </div>

                <ul>
                    <li @click="activeYw(0)" @mouseleave="closeYw(0)" :class="Yw[0] ? 'active' : ''">
                        <img src="@/assets/pc/service/yewu-1.jpg" />
                        <div class="mask">品牌公关咨询及传播，产品数字营销及传播，领导人形象建设及体系运营，危机公关咨询及管理，媒体生态建立及维护，事件营销，区域营销，更多...</div>
                    </li>
                    <li @click="activeYw(1)" @mouseleave="closeYw(1)" :class="Yw[1] ? 'active' : ''">
                        <img src="@/assets/pc/service/yewu-2.jpg" />
                        <div class="mask">基于5G时代下的全面社会化沟通渠道运营，双微、短视频平台、知识社区的策略规划及运维，以“中央直播间”为核心模式的企业直播阵地，媒体平台内容分发与自媒体传播，用户运营等...</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-wrap product">
            <div class="wrap-content">
                <div class="col-title">
                    <h1>创新沟通产品</h1>
                    <div>Innovative Communication-based Product</div>
                </div>

                <ul>
                    <li><img src="@/assets/pc/service/product-1.png" /></li>
                    <li><img src="@/assets/pc/service/product-2.png" /></li>
                    <li><img src="@/assets/pc/service/product-3.png" /></li>
                    <li><img src="@/assets/pc/service/product-4.png" /></li>
                </ul>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
export default {
    name: "pc-service",
    components: { Nav, Footer },
    data() {
        return {
            Yx: [false, false],
            Yw: [false, false],
        };
    },
    methods: {
        activeYx(idx) {
            var v = this.Yx[idx];
            this.Yx[idx] = !v;
            this.$forceUpdate();
        },
        closeYx(idx) {
            this.Yx[idx] = false;
            this.$forceUpdate();
        },
        activeYw(idx) {
            var v = this.Yw[idx];
            this.Yw[idx] = !v;
            this.$forceUpdate();
        },
        closeYw(idx) {
            this.Yw[idx] = false;
            this.$forceUpdate();
        },
    },
};
</script>

<style scoped>
@import url("../../../common/css/pc.css");
.yewu ul li .mask,
.yingxiao ul li .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 61, 147, 0);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    line-height: 30px;
    font-size: 14px;
    opacity: 0;
    transform: scale(0);
}

.yewu ul .active .mask,
.yingxiao ul .active .mask {
    background: rgba(0, 61, 147, 0.99);
    opacity: 1;
    transform: scale(1);
}

.product {
    background: #0048b1;
}

.product ul li:hover {
    transform: scale(1.1);
}
.yewu {
    background: url("~@/assets/pc/service/yewu-bg.jpg") center bottom;
    background-size: 100% auto;
    height: 960px;
}
.yewu .col-title {
    margin-top: 100px;
}

.yingxiao .bg {
    background: url("~@/assets/pc/service/yingxiao-bg.jpg") center bottom;
    height: 960px;
}
.yingxiao .col-title,
.product .col-title {
    border-color: #fff;
    margin-top: 100px;
}
.yingxiao .col-title *,
.product .col-title * {
    color: #fff;
}

.service .wrap-content ul li:hover {
    transform: scale(1.05);
}

.service .wrap-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/service/banner-bg.jpg") center bottom;
        height: 500px;
    }

    .header .wrap-content .content h3 {
        font-size: 24px;
    }
    .header .wrap-content .content h1 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        font-size: 20px;
    }
    .yingxiao ul {
        margin-left: 64px;
        text-align: center;
    }
    .yingxiao ul li {
        border-radius: 12px;
        overflow: hidden;
        display: inline-block;
        margin-right: 60px;
        position: relative;
        cursor: pointer;
        width: 350px;
    }
    .yewu ul {
        margin-left: 66px;
        text-align: center;
    }
    .yewu ul li {
        border-radius: 12px;
        overflow: hidden;
        display: inline-block;
        margin-right: 60px;
        position: relative;
        cursor: pointer;
        width: 350px;
    }
    .service .wrap-content ul {
        display: flex;
        justify-content: space-between;
        margin: 30px 100px 60px 100px;
    }
    .service .wrap-content ul li {
        overflow: hidden;
        border-radius: 10px;
        transition: all 0.3s;
        width: 250px;
        margin: 0 11px;
    }
    .service .bg {
        background: url("~@/assets/pc/service/service-bg.jpg") center bottom;
        height: 860px;
    }
    .service .wrap-content h1 {
        font-size: 26px;
        color: #333333;
        margin: 70px 0 0 0;
        font-weight: normal;
    }
    .service .wrap-content h2 {
        font-size: 16px;
        color: #333333;
        font-weight: normal;
        margin: 10px 0 40px 0;
    }
    .service .wrap-content p {
        font-size: 16px;
        color: #333333;
        margin: 6px 0;
    }

    .yingxiao {
        background: url("~@/assets/pc/service/yingxiao-bg.jpg") center bottom;
        background-size: 100% auto;
        height: 760px;
    }
    .yewu {
        background: url("~@/assets/pc/service/yewu-bg.jpg") center bottom;
        background-size: 100% auto;
        height: 760px;
    }
    .product ul {
        margin-bottom: 100px;
        margin-left: 66px;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .product ul li {
        overflow: hidden;
        display: inline-block;
        margin-right: 50px;
        transition: all 0.3s;
        width: 16%;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/service/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content h3 {
        font-size: 24px;
    }
    .header .wrap-content .content p {
        font-size: 20px;
    }
    .yingxiao ul {
        margin: 100px 0 50px 64px;
        text-align: center;
    }
    .yingxiao ul li {
        border-radius: 12px;
        overflow: hidden;
        display: inline-block;
        margin-right: 60px;
        position: relative;
        cursor: pointer;
        width: 35%;
    }
    .yewu ul {
        margin: 100px 0 0 66px;
        text-align: center;
    }
    .yewu ul li {
        border-radius: 12px;
        overflow: hidden;
        display: inline-block;
        margin-right: 60px;
        position: relative;
        cursor: pointer;
        width: 35%;
    }
    .service .wrap-content ul li {
        overflow: hidden;
        border-radius: 10px;
        transition: all 0.3s;
        width: 25%;
        margin: 0 20px;
    }
    .service .wrap-content ul {
        display: flex;
        justify-content: center;
        width: 1280px;
        margin: 30px 0 60px 0;
    }
    .service .bg {
        background: url("~@/assets/pc/service/service-bg.jpg") center bottom;
        height: 960px;
    }
    .service .wrap-content h1 {
        font-size: 30px;
        color: #333333;
        margin: 70px 0 0 0;
        font-weight: normal;
    }
    .service .wrap-content h2 {
        font-size: 20px;
        color: #333333;
        font-weight: normal;
        margin: 10px 0 40px 0;
    }
    .service .wrap-content p {
        font-size: 16px;
        color: #333333;
        margin: 6px 0;
    }
    .product ul {
        margin-bottom: 100px;
        margin-left: 66px;
        display: flex;
        justify-content: space-between;
        text-align: center;
    }
    .product ul li {
        overflow: hidden;
        display: inline-block;
        margin-right: 50px;
        transition: all 0.3s;
        width: 16%;
    }
}
</style>
